"use strict";

import axios from "axios";
var baseControllerAddress = 'security/';

export default {
    post: (action, vm) => {
        return axios.post('/api/' + baseControllerAddress + action, vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                if (error.response.status != 401) {
                    alert(error);
                }
            });
    },

}