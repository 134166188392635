<template>
    <v-container fluid>
        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="12">
                    <h1>Permissions</h1>
                </v-col>

                <v-col align="end">
                    <v-btn color="success"
                           elevation="2"
                           large
                           @click="savePermissions()">
                        {{ $t('common_save') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
                <template>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th> Name</th>
                                    <th v-for="role in model.availableRoles"
                                        :key="role.name"
                                        class="text-left">
                                        {{ role.name }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="permission in model.availablePermissions"
                                    :key="permission.name">
                                    <td>{{ permission.name }}</td>
                                    <td v-for="rp in permission.rolesPermissions"
                                        :key="rp.roleName">
                                        <v-checkbox v-model="rp.allowed"></v-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
    </v-container>
</template>

<style scoped>

    /* makes arrows next to table headers (filter buttons) visible acc. to  Annes design, it is not editable */
    /deep/ .v-icon {
        opacity: 1 !important;
    }

    /* removes borders top an bot for every generated row */
    /deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
        border-top: 0px !important;
        border-bottom: 0px !important;
    }

        /* adds a border from top to bottom between every piece of horizontal data */
        /deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:not(:last-child), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
            border-right: 1px solid #5a5f62;
        }

    /* adds yellow background for every 2 rows */
    /deep/ tr:nth-child(even) {
        background-color: #f5f4ec !important;
    }

    /* gives table headers rounder corners */
    /deep/ tr th:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    /* gives table headers rounder corners */
    /deep/ tr th:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    /deep/ .v-data-table > .v-data-table__wrapper > table > thead {
        background-color: #707070;
    }

    /deep/ .v-text-field {
        min-width: 120px;
    }

    /* vertical lines between table header titles */
    /deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
        border-right: 1px solid #5a5f62 !important;
    }
</style>
<style scoped>
    .hideHint {
        visibility: hidden;
        display: none;
    }
</style>
<script>
    import securityService from '@/services/securityService';
    import router from '@/router';

    export default {
        name: 'permissionModel',
        data: () => ({
            model: {},
        }),
        methods: {
            savePermissions() {
                securityService.post('permissionsSave', this.model).then(data => {
                    router.go(-1)
                });
            },
        },
        mounted() {

            securityService.getPermissions()
                .then(data => {
                    this.model = data;
                });

        },
    }
</script>